import React, { useState, useEffect, useMemo } from "react";
import { navigate, Link } from "gatsby";
import { useSelector } from "react-redux";

import * as LocationStyles from "../styles/location.module.css";

import ColumbiaCareLogo from "../images/columbia-care-text-logo.png";
import Button from "../components/button/Button";
import JaneEmbed from "../../../../common/providers/iheartjane/jane-embed/JaneEmbed";
import { getCurrentCampaigns } from "@col-care/common/utilities/index";
import { GhubLink } from "../../../../common/common";

export default function LocationRecreationalMenu(props) {
  const state = useSelector((state) => state);
  const _campaigns = useSelector(
    (state) => state.hydrate.databagData.campaigns
  );
  const locationData = state.hydrate.locationData;

  const janeRecId = locationData.location?.vendor_integrations.jane_rec_id;
  // const janeRecId = 1850

  const currentCampaigns = getCurrentCampaigns(
    locationData.location.location_id,
    _campaigns
  );

  const campaigns = useMemo(() => {
    return currentCampaigns.reduce((acc, campaign) => {
      acc[campaign.unique_id] = campaign;
      return acc;
    }, {});
  }, [_campaigns]);

  const [janeCheckoutCompleted, setJaneCheckoutCompleted] = useState(null);
  const [janeCheckoutUpdatedCart, setJaneCheckoutUpdatedCart] = useState(null);

  // @todo: CMS setting
  // if (props.pageContext?.content?.state === "New York" || props.pageContext?.content?.state === "Delaware" || props.pageContext?.content?.state === "Maryland") {
  //   disableAuth = false;
  // }

  useEffect(() => {
    if (janeCheckoutCompleted) {
      if (typeof window === "object" && window.twq) {
        window.twq("event", "tw-ofxar-ofxaw", {
          value: janeCheckoutCompleted.properties.estimatedTotal,
          currency: "USD",
        });
      }
    }
  }, [janeCheckoutCompleted]);

  const janeEmbedConfig = {
    // iframeSrc: `https://www.iheartjane.com/embed/stores/${janeRecId}/menu`,
    iframeSrc: `https://api.iheartjane.com/v1/stores/${janeRecId}/embed.js`,
    themeColor: "#202245",
    navigationColor: "#202245",
    ctaTextColor: "#ffffff",
    buttonBorderRadius: "0.5rem",
    action: "initEmbeddedOptions",
    disableAuth: false,
  };

  if (
    locationData &&
    locationData.url &&
    locationData.location.address.state === "Ohio" &&
    process.env.GHUB_DATADOG_SERVICE.indexOf("-oh-comms") === -1
  ) {
    let timeout = setTimeout(() => {
      if (typeof window !== "undefined") {
        window.location.href = locationData.location.store_information.website;
      }
    }, 3000);
    return (
      <div>
        <section className={LocationStyles.LocationHero}>
          <div className={LocationStyles.LocationHeroInner}>
            <img
              className={LocationStyles.ColumbiaCareLogo}
              src={ColumbiaCareLogo}
              alt="columbia care"
            />
            <h1 className={LocationStyles.LocationHeroHeader}>
              {" "}
              {locationData.location.store_information.nickname_location
                ? `${locationData.location.store_information.nickname_location}`
                : `${locationData.location.address.city}`}
              {"  "}
            </h1>
          </div>
        </section>
        <section className={LocationStyles.StoreBody}>
          <div style={{ margin: "0 auto", width: "100%", textAlign: "center" }}>
            <h2>Redirecting you...</h2>
          </div>
        </section>
      </div>
    );
  } else if (janeRecId) {
    return (
      <>
        <section className={LocationStyles.LocationHero}>
          <div className={LocationStyles.LocationHeroInner}>
            <img
              className={LocationStyles.ColumbiaCareLogo}
              src={ColumbiaCareLogo}
              alt="columbia care"
            />
            <h1 className={LocationStyles.LocationHeroHeader}>
              {" "}
              {locationData.location.store_information.nickname_location
                ? `${locationData.location.store_information.nickname_location}`
                : `${locationData.location.address.city}`}
            </h1>
            <div className={LocationStyles.location_header_button_container}>
              <Button
                theme="transparent"
                key={0}
                label="View Store"
                onClick={(e) => {
                  navigate(locationData.location.url);
                }}
              />
            </div>
          </div>
        </section>
        <div>
          {getCampaignMarkup(campaigns, "420_2024")}
          <JaneEmbed
            {...props}
            janeEmbedConfig={janeEmbedConfig}
            janeCheckoutCompleted={janeCheckoutCompleted}
            setJaneCheckoutCompleted={setJaneCheckoutCompleted}
            janeCheckoutUpdatedCart={janeCheckoutUpdatedCart}
            setJaneCheckoutUpdatedCart={setJaneCheckoutUpdatedCart}
            legacy={true}
          />
        </div>
      </>
    );
  }

  return null;
}

const getCampaignMarkup = (campaigns, key) => {
  if (!campaigns[key]) {
    return null;
  }

  if (!campaigns[key].elements[0] && !campaigns[key].locations[0]) {
    return null;
  }

  return (
    <div className={LocationStyles.campaign_contatiner}>
      {campaigns[key]?.elements?.[0]?.banner?.heading && (
        <h4>{campaigns[key].elements[0].banner.heading}</h4>
      )}
      <GhubLink
        destination={
          campaigns[key]?.elements?.[0]?.banner?.calls_to_action?.cta_1?.href
        }
      >
        <img
          alt={
            campaigns[key]?.locations[0]?.file
              ? campaigns[key]?.locations[0].file.title
              : campaigns[key]?.markets[0].file.title
          }
          src={
            campaigns[key]?.locations[0]?.file
              ? campaigns[key]?.locations[0].file.url
              : campaigns[key]?.markets[0].file.url
          }
        />
      </GhubLink>
    </div>
  );
};
